import React, { useState } from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import Layout from "../components/layout/ca"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import ToShare from "../components/restaurant-menu/to-share"
import Starters from "../components/restaurant-menu/starters"
import Carpaccios from "../components/restaurant-menu/carpaccios"
import PastaAndRices from "../components/restaurant-menu/pasta-and-rices"
import SeaFood from "../components/restaurant-menu/sea-food"
import Fish from "../components/restaurant-menu/fish"
import Meet from "../components/restaurant-menu/meet"
import Beefs from "../components/restaurant-menu/beefs"
import Desserts from "../components/restaurant-menu/desserts"

const CartaPage = (props) => {
	const [menuCategory, setMenuCategory] = useState('to-share');
	const seoDescription = "Plats per compartir, entrants freds, carpaccios i tàrtars, marisc, peixos, carns, bous i vedelles i postres";
	let SelectedCategory;
	if (menuCategory === 'to-share') {
		SelectedCategory = ToShare;
	} else if (menuCategory === 'starters') {
		SelectedCategory = Starters;
	} else if (menuCategory === 'carpaccios') {
		SelectedCategory = Carpaccios;
	} else if (menuCategory === 'pasta-and-rices') {
		SelectedCategory = PastaAndRices;
	} else if (menuCategory === 'sea-food') {
		SelectedCategory = SeaFood;
	} else if (menuCategory === 'meet') {
		SelectedCategory = Meet;
	} else if (menuCategory === 'fish') {
		SelectedCategory = Fish;
	} else if (menuCategory === 'beefs') {
		SelectedCategory = Beefs;
	} else if (menuCategory === 'desserts') {
		SelectedCategory = Desserts;
	} 

	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Carta"
	    	lang="ca"
	    	description={seoDescription}
	    />
	    <HeroImage 
	      titleText="Carta"
	      image="carta" 
	    />
	    <MenuSection className="menu">
	      <MenuHeaders className="menu-headers">
	        <li onClick={() => setMenuCategory('to-share')}>
	        	Per compartir
	        </li>
	        <li onClick={() => setMenuCategory('starters')}>
	        	Entrants Freds
	        </li>
	        <li onClick={() => setMenuCategory('carpaccios')}>
	        	Carpaccios i tàrtars
	        </li>
	        <li onClick={() => setMenuCategory('pasta-and-rices')}>
	        	Pastes i arrossos
	        </li>
	        <li onClick={() => setMenuCategory('sea-food')}>
	        	Marisc
	        </li>
	        <li onClick={() => setMenuCategory('fish')}>
	        	Peixos
	        </li>
	        <li onClick={() => setMenuCategory('meet')}>
	        	Carns
	        </li>
	        <li onClick={() => setMenuCategory('beefs')}>
	        	Bous i Vedelles
	        </li>
	        <li onClick={() => setMenuCategory('desserts')}>
	        	Postres
	        </li>
	        <Link to="/menu">
	        	<h2>Menú d'entre setmana</h2>
	        </Link>
	      </MenuHeaders>
	      <MobileView>
		      <SelectBox value={menuCategory} onChange={(event) => setMenuCategory(event.target.value)}>
		        <option value="to-share">Per compartir</option>
		        <option value="starters">Entrants Freds</option>
		        <option value="carpaccios">Carpaccios i tàrtars</option>
		        <option value="pasta-and-rices">Pastes i arrossos</option>
		        <option value="sea-food">Marisc</option>
		        <option value="fish">Peixos</option>
		        <option value="meet">Carns</option>
		        <option value="beefs">Bous i Vedelles</option>
		        <option value="desserts">Postres</option>
		      </SelectBox>
		      <Link to="/menu">
		      	<span>Menú d'entre setmana</span>
		      </Link>
		    </MobileView>
	      <SelectedCategory lang='ca'/>
	    </MenuSection>
	  </Layout>
	)
}

const MenuSection = styled.div`
	margin: 140px 70px;
	display: flex;
	@media (max-width: 540px){
		margin: 100px 5px;
	  flex-direction: column;
	}
`

const SelectBox = styled.select`
	font-size: 16px;
	margin-right: 10px;
`

const MobileView = styled.div`
	@media (min-width: 540px){
	  display: none;
	}
`

const MenuHeaders = styled.div`
	display: flex;
	flex-direction: column;
	list-style-type: none;
	width: 250px;
	li {
	  cursor: pointer;
	  padding: 10px;
	  line-height: 20px;
	}
	@media (max-width: 540px){
	  display: none;
	}
`

export default CartaPage